import { j as s, C as v, c, I as h, b as M, L as I } from "./ToggleSwitch-DlaivjHi.mjs";
import { useId as z } from "react";
const q = "_JSONPrettyPrintMain_qmy6t_2", A = "_string_qmy6t_6", F = "_number_qmy6t_10", J = "_boolean_qmy6t_14", w = "_key_qmy6t_22", u = {
  JSONPrettyPrintMain: q,
  string: A,
  number: F,
  boolean: J,
  null: "_null_qmy6t_18",
  key: w
};
function H() {
  const e = /* @__PURE__ */ new WeakSet();
  return (n, t) => {
    if (!(typeof Node == "function" && t instanceof Node)) {
      if (typeof t == "object" && t !== null) {
        if (e.has(t))
          return;
        e.add(t);
      }
      return t;
    }
  };
}
function O(e, n = 2) {
  return JSON.stringify(e, H(), n);
}
function E(e) {
  return e = e.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;"), e.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (n) => {
      let t = u.number;
      return /^"/.test(n) ? /:$/.test(n) ? t = u.key : t = u.string : /true|false/.test(n) ? t = u.boolean : /null/.test(n) && (t = u.null), t ?? (t = ""), `<span class='${t}'>${n}</span>`;
    }
  );
}
function me({ json: e }) {
  const n = typeof e == "string" ? e : O(e, 2), t = E(n);
  return /* @__PURE__ */ s.jsx("pre", { className: u.JSONPrettyPrintMain, dangerouslySetInnerHTML: { __html: t } });
}
const P = "_alert_1iits_1", B = "_icon_1iits_5", R = "_iconContainer_1iits_10", d = {
  alert: P,
  icon: B,
  iconContainer: R,
  "icon-success": "_icon-success_1iits_15",
  "icon-warning": "_icon-warning_1iits_19",
  "icon-error": "_icon-error_1iits_23"
}, T = "_alert_16kt4_1", G = "_flex_16kt4_6", p = {
  alert: T,
  flex: G,
  "kind-success": "_kind-success_16kt4_10",
  "kind-warning": "_kind-warning_16kt4_15",
  "kind-error": "_kind-error_16kt4_20",
  "kind-neutral": "_kind-neutral_16kt4_25"
};
function S({ kind: e, className: n, style: t, children: r, noFlex: i = !1, ...o }) {
  const l = e === "error" ? "alert" : "status";
  return /* @__PURE__ */ s.jsx(
    v,
    {
      ...o,
      style: t,
      className: c(p.alert, n, e && p[`kind-${e}`], { [p.flex]: !i }),
      role: l,
      children: r
    }
  );
}
const Z = {
  warning: "alertTriangle",
  error: "alertCircle",
  success: "checkCircle"
};
function pe({ kind: e, size: n, className: t, style: r, children: i }) {
  const o = e === "error" ? "alert" : "status";
  return /* @__PURE__ */ s.jsxs(S, { kind: e, size: n, role: o, className: c(d.alert, t), style: r, children: [
    /* @__PURE__ */ s.jsx("div", { className: c(d[`icon-${e}`], d.iconContainer), children: /* @__PURE__ */ s.jsx(h, { name: Z[e], className: d.icon, title: e }) }),
    /* @__PURE__ */ s.jsx("div", { children: i })
  ] });
}
const K = "_container_r63zv_1", Q = "_neutral_r63zv_21", U = "_success_r63zv_26", y = {
  container: K,
  default: "_default_r63zv_15",
  neutral: Q,
  success: U
};
function xe({ kind: e = "default", children: n }) {
  return /* @__PURE__ */ s.jsx("div", { className: c(y.container, y[e]), children: n });
}
const V = "_center_8efln_1", W = "_centerIntrinsic_8efln_12", X = "_container_8efln_19", Y = "_flex_8efln_23", g = {
  center: V,
  centerIntrinsic: W,
  container: X,
  flex: Y
};
function be({
  as: e = "div",
  maxSize: n = "fix-content-total",
  gutter: t = "300",
  className: r,
  centeredChildren: i,
  container: o,
  flex: l,
  style: m,
  ..._
}) {
  return /* @__PURE__ */ s.jsx(
    e,
    {
      ..._,
      className: c(
        g.center,
        { [g.centerIntrinsic]: i, [g.container]: o, [g.flex]: l },
        r
      ),
      style: {
        "--container-size": `var(--betterplace-sizing-${n})`,
        "--container-gutters": `var(--betterplace-spacing-${t})`,
        ...m
      },
      children: _.children
    }
  );
}
const D = "_grid_hx5c1_1", ee = "_gridAutoFit_hx5c1_6", ne = "_gridColumns_hx5c1_10", te = "_flex_hx5c1_14", f = {
  grid: D,
  gridAutoFit: ee,
  gridColumns: ne,
  flex: te
};
function ke({ children: e, space: n = "200", columns: t, minElementWidth: r, flex: i, style: o }) {
  return /* @__PURE__ */ s.jsx(
    "div",
    {
      className: c(f.grid, {
        [f.gridAutoFit]: !t && r,
        [f.gridColumns]: !!t,
        [f.flex]: i
      }),
      style: {
        ...o,
        "--space": `var(--betterplace-spacing-${n})`,
        //   We might want to extend this to use our tokens
        "--min": r,
        "--columns": t
      },
      children: e
    }
  );
}
const se = "_groupLabel_1kfi8_1", re = "_fieldset_1kfi8_6", ie = "_legend_1kfi8_12", ce = "_legendMedium_1kfi8_16", oe = "_fieldContainer_1kfi8_42", ae = "_groupHint_1kfi8_46", le = "_groupError_1kfi8_51", _e = "_errorMessage_1kfi8_60", ue = "_invisibleLegend_1kfi8_67", a = {
  groupLabel: se,
  fieldset: re,
  legend: ie,
  legendMedium: ce,
  fieldContainer: oe,
  groupHint: ae,
  groupError: le,
  errorMessage: _e,
  invisibleLegend: ue
};
function de({ className: e, hint: n, required: t, children: r }) {
  return /* @__PURE__ */ s.jsxs("legend", { className: c(e, a.legend), children: [
    /* @__PURE__ */ s.jsxs("div", { className: a.groupLabel, children: [
      r,
      t && /* @__PURE__ */ s.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    n ? /* @__PURE__ */ s.jsx("div", { className: a.groupHint, children: n }) : null
  ] });
}
function Ne({
  children: e,
  classNames: n,
  legend: t,
  legendSize: r = "small",
  hint: i,
  required: o,
  error: l,
  layout: m = "horizontal",
  layoutProps: _ = {
    space: "none"
  },
  errorDisplay: x = "all",
  invisibleLegend: C,
  ...b
}) {
  const $ = c(a.fieldContainer, _ == null ? void 0 : _.className, {
    [a.groupError]: !!l && x !== "text"
  }), j = m === "vertical" ? M : I, L = z(), k = b.id || L, N = l ? `${k}-error` : void 0;
  return /* @__PURE__ */ s.jsxs(
    "fieldset",
    {
      id: k,
      className: c(a.fieldset, n == null ? void 0 : n.fieldset),
      "aria-describedby": N,
      ...b,
      children: [
        t || i ? /* @__PURE__ */ s.jsx(
          de,
          {
            required: o,
            hint: i,
            className: c(n == null ? void 0 : n.legend, {
              [a.legendMedium]: r === "medium",
              [a.invisibleLegend]: C
            }),
            children: t
          }
        ) : null,
        /* @__PURE__ */ s.jsx(j, { className: $, ..._, children: e }),
        l && x !== "border" ? /* @__PURE__ */ s.jsx("p", { id: N, className: a.errorMessage, "aria-live": "polite", role: "alert", children: l }) : null
      ]
    }
  );
}
export {
  pe as A,
  xe as B,
  S as C,
  Ne as F,
  me as J,
  be as L,
  ke as a
};
